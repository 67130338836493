.project-container {
  background-color: rgba(24, 24, 24, .8);
}

.search-box {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100000;
  width: 40%;
  max-width: 575px;
}

.tw-connected-wallet__network-icon{
  width:26px !important;
  height:26px !important;

}
.tw-connected-wallet__wallet-icon{
  width:26px !important;
  height:26px !important;
 
}
.tw-connected-wallet__balance{
  color:#0a0a0a !important;

}

.ais-SearchBox-input {
  border: 2px solid #5A5E6B;
  /* background-color: rgb(54, 54, 54, 1); */
  background-color: rgba(24, 24, 24, 1);
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.ais-SearchBox-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: .5em;
  width: .5em;
  border-radius: 50em;
  background: url('../../images/x.png') no-repeat 50% 50%;
  background-size: contain;
  opacity: 1;
  /* pointer-events: none; */
  cursor: pointer;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}

.ais-Hits-list {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  border-radius: 15px;
  /* background-color: rgb(54, 54, 54, 1); */
  background-color: rgba(24, 24, 24, 1);
  border: 2px solid #5A5E6B;
  list-style-type: none;
  z-index: 100000;
}

.ais-Hits-list li {
  border-bottom: 2px solid #5A5E6B;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.ais-Hits-list li:hover {
  transform: scale(1.02);
}

.ais-Hits-list li:last-child {
  border-bottom: none
}


.ais-Hits-item {
  width: 100%;
  margin-left: -20px;
  left: 0;
  cursor: pointer;
}

.hit {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.hit-img-boarder {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-box-shadow: 10px 10px 34px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 34px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 34px -12px rgba(0, 0, 0, 0.75);
}

.hit-img-boarder>img {
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  object-fit: cover
}

.hit>h5 {
  color: #919191;
  margin-left: 7.5px;
}

.hit-user-id {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hit-user-name {
  background: -webkit-linear-gradient(45deg,
      #b5e5ff 2.35%,
      #c2ceff 31.77%,
      #e1a9f6 68.99%,
      #f9abe7 102.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu-bar-contain {
  /* width: '100%'; */
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #1D1D1D;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.32);
  z-index: 600;
  position: sticky;
  top: 0;
  left: 0;
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  /* padding-left: 1em;
  padding-right: 1em; */
  width: '100%';
  width: 5000px;
  padding-left: 10px;
  z-index: 750;
  /* position: relative; */
}

.avatar-bubble {
  position: absolute;
  width: 150px;
  border: 3px solid #5A5E6B;
  background: #181819;
  margin-top: 10px;
  border-radius: 15px;
  height: 90px;
  overflow: hidden;
  top: 150;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.avatar-bubble-div {
  display: flex;
  height: 45px;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.avatar-bubble-div:first-child {
  border-bottom: 3px solid #545454;
}

.menu-bar-right {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 24px;
  align-items: center;
}

.menu-bar img {
  height: 42px;
  width: 35px;
}

.connect-wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px !important; 
  max-width: 182px !important;
  max-height: 46px;
  overflow: hidden;
  transform: scale(0.85);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  font-size: 16px;
  background-color: #C6C7FF !important;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));

}

.header-image {
  image-rendering: crisp-edges;
  width: 100%;

  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  height: 120px;
  background-size: cover;
  z-index: 0;
}



h1 {
  font-family: SFProHeavy;
  font-size: 24px;
  color: white;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.project-id-container {
  display: flex;
  background-color: #d9d9d922;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 20px;
  width: fit-content;
}

.project-id-label {
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 14px;
  color: #A7A7A7;
  padding-right: 0.5em;
}

.project-id {
  font-family: SFProRegular;
  font-weight: 500;
  font-size: 14px;
  color: #BCBFF5;
  padding-right: 0.5em;
}

.project-id-clipboard {
  color: #A7A7A7;
  font-size: 14px;
  align-self: center;
}

.project-description {
  color: #E1E8FD;
  width: 90%;
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-top: 0.2em;
}

.project-description a {
  color: #d1bbf9;
}

.project-social-icons {
  margin-top: 1em;
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  width: 60%;
}

.project-social-icons a {
  color: white;
  margin-right: 0.65em;
  padding: 0.35em 0.35em 0.1em 0.35em;
  border-radius: 50%;
}

.project-social-icon:hover {
  background-color: #2C2C2C;
}

.gradient-text {
  background: linear-gradient(90deg, #56CCF2 -7.25%, #C6C7FF 24.16%, #E1A9F6 55.53%, #F9ABE7 108.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.user-profile-container {


  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  width: 100%;

}

@media screen and (min-width: 600px) {
  .leaderboard-container {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
  }

  .user-profile-container {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    width: 100%;

  }

  .hit>h5 {
    margin-left: 15px;
  }

  .menu-bar {
    /* width: 100%; */
    z-index: 10;
  }

  .menu-bar-left {
    padding-left: 1em;
    cursor: pointer;
  }

  .menu-bar-right {
    padding-right: 1em;
  }

  .header-image {
    height: 140px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .project-container {
    width: 60%;
    background-color: rgba(24, 24, 24, .8);

  }
}

@media screen and (min-width: 1260px) {
  .menu-bar {
    /* padding-left: 90px; */
  }
}