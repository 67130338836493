.community-container {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background-color: #1E2028;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  align-items: center;
  background-image: url('../../images/huddlnBg.png');
}

.community-container-inner {
  width: 100%;
  max-width: 520px;
  background-color: rgba(24, 24, 24, .8);
  padding: 0 10px 10px 10px;
  -webkit-box-shadow: -2px 10px 17px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 17px -5px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 17px -5px rgba(0, 0, 0, 0.75);
}

.header-image {
  image-rendering: crisp-edges;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  height: 140px;
}

.community-header-container {
  position: relative;
  padding: 40px 10px 0px 10px;
}

.community-title {
  display: flex;
  flex-direction: column;
  position: relative;
}

.community-user-profile {
  border: 6px solid #FFFFFF;
  position: absolute;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  width: 80px;
  height: 80px;
  top: -40px;
  left: 10px;
}

.community-title-xp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.project-id-container {
  display: flex;
  background-color: #d9d9d922;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 20px;
  width: fit-content;
}

.project-id-label {
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 14px;
  color: #A7A7A7;
  padding-right: 0.5em;
}

.project-id {
  font-family: SFProRegular;
  font-weight: 500;
  font-size: 14px;
  color: #BCBFF5;
  padding-right: 0.5em;
}

.project-id-clipboard {
  color: #A7A7A7;
  font-size: 14px;
  align-self: center;
}

.project-description {
  color: #E1E8FD;
  width: 90%;
  font-family: SFProRegular;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-top: 0.2em;
}

.project-description a {
  color: #d1bbf9;
}

.project-social-icons-contain {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;

}

.project-social-icon {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
  transition: all .1s ease-in-out;
}

.project-social-icon:hover {
  background-color: #3C3C3C !important;
  transform: scale(1.1) !important;
}

.gradient-text {
  background: linear-gradient(90deg, #56CCF2 -7.25%, #C6C7FF 24.16%, #E1A9F6 55.53%, #F9ABE7 108.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* LEADER BOARD TAB */
.leaderboard-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.leaderboard-container>p {
  color: #ccc;
  font-size: 12px;
  align-self: flex-start;
  margin: -10px 0 10px 10px;
}

.leaderboard-tabs-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-left: 10px;
}

.leaderboard-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 30px;
  padding: 0 16px;
  border-radius: 15px;
  background: #232323;
  color: #7A7A7A;
  cursor: pointer;
  font-size: 16px;
}

.leaderboard-tab.active {
  background: #C6C7FF;
  color: #232323;

}

.leaderboard-tab.not-active:hover {
  background: #363636;
  color: white;
}

.total-score-container {
  font-family: SFProRegular;
  font-weight: 800;
  font-size: 18px;
  color: #A7A7A7;
  display: flex;
}

.total-score {
  display: flex;
  margin-right: 1em;
}

.total-members {
  display: flex;
}

.total-score-number {
  margin-right: 0.35em;
}

.community-tab {
  /* padding-left: 10px; */
}

.community-body {
  padding: 0 10px 20px 10px;
}

/* REDEMPTION TAB */
.redemption-tab-contain {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.redemption-tab-contain-inner-column {
  display: grid;
  grid-template-columns: 220px 220px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.redemption-tab-contain-inner-bon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.redemption-tab-contain-inner-bon>h3 {
  margin-top: -60px;
  margin-bottom: 20px;
  color: #7A7A7A
}

@media screen and (min-width: 720px) {
  .community-container-inner {
    max-width: 800px;
  }

  .community-header-container {
    padding: 40px 20px 0px 20px;
  }

  .community-user-profile {
    left: 20px;
  }

  .community-header-body {
    padding: 0 20px 20px 20px;
  }

  .redemption-tab-contain {
    column-count: 3;
  }

  .leaderboard-tab {
    margin-right: 16px;
    height: 35px;
    padding: 0 20px;
    border-radius: 17.5px;
    font-size: 16px;
  }

  .redemption-tab-contain-inner {
    max-width: 100%;
  }

  .redemption-tab-contain-inner-column {
    grid-template-columns: 220px 220px 220px;
  }
}