.tab-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 44px;
  margin-bottom: 28px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: '100%';
}

.tab.active {
  color: #C6C7FF;
}


.tab:hover {
  cursor: pointer;
}

.tab-bar {
  background-color: #C6C7FF;
  width: 120px;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 10px;
  transition: all .2s ease-in-out;
}