.open {
  display: block;
  width: 100%;
  color: white;
}

.closed {
  display: none;
}

.top-3-scores {
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 1em;
  padding-top: 100px;
  position: relative
}

.top-score {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: SFProRegular;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
}


.top-score-verified-lottie {
  background-color: transparent;
  position: absolute;
  left: 25px;
  top: -86px;
  z-index: 99;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
}

.top-score-verified-lottie img {
  width: 20px;
  height: 20px;
}

.best-score-verified-lottie {
  background-color: transparent;
  display: inline;
  position: relative;
  left: 31px;
  top: -105px;
  z-index: 99;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.8));
}

.best-score-verified-lottie img {
  width: 20px;
  height: 20px;
}

.top-score-name {
  color: white;
  margin-bottom: 0.3em;
}

.best-score {
  justify-self: center;
}

.best-score-name {
  color: white;
  margin-bottom: 0.3em;
  font-weight: 800;
}

.top-score-rank {
  color: #C6C7FF;
  margin-bottom: 0.5em;
  margin-top: 1em;
  font-weight: 800;
}

.best-score-rank {
  color: #C6C7FF;
  margin-bottom: 0.5em;
  font-weight: 800;
}

.top-score-avatar {
  width: 4.5em;
  height: 4.5em;
  margin-bottom: 0.6em;
  position: relative;
}

.best-score-avatar {
  width: 5.5em;
  height: 5.5em;
  margin-bottom: 1em;
  position: relative;
}


.top-score-avatar img {
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  width: 4.5em;
  height: 4.5em;
  object-fit: cover;
}

.top-score-avatar-fix {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  border: 5px solid white;
}


.best-score-avatar-fix {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 0%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  border: 6px solid white;
}

.best-score-avatar img {
  border-radius: 50%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
  width: 5.5em;
  height: 5.5em;
  object-fit: cover;
}

.top-score:hover img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8)) brightness(70%);
  transition: all .2s ease;
}

.all-score-rows {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding-left: 16px;
  padding-right: 16px;
}

.load-more-container {
  display: flex;
  justify-content: center;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.8));


}

#load-more {


  border: none;
  width: 12em;
  padding: 8px;
  margin-top: 2em;
  background: #C6C7FF;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProHeavy;
  color: #171818;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  cursor: pointer;
}

#load-more:hover {
  background: #aaabfb;
}

@media screen and (max-width: 600px) {
  .top-score {
    width: 25%;
  }

  .top-score-verified-lottie {
    left: 21px;
    top: -80px;
  }

  .best-score-verified-lottie {
    left: 27px;
    top: -100px;
  }
}

@media screen and (min-width: 600px) {
  .top-3-scores {
    justify-content: space-evenly;
  }

  .top-score {
    margin: 20px;
    width: 120px;
    max-width: 120px;
    min-width: 120px;
  }

  .top-score-avatar {
    width: 5em;
    height: 5em;
  }

  .top-score-avatar img {
    width: 5em;
    height: 5em;
  }

  .best-score-avatar {
    width: 6em;
    height: 6em;
  }

  .best-score-avatar img {
    width: 6em;
    height: 6em;
  }

}


@media screen and (min-width: 800px) {
  .top-score {
    width: 100px;
    min-width: 33%;
  }
}

@media screen and (min-width: 500px) {
  .top-score {
    margin: 10px;
  }
}


@media screen and (min-width:1200px) {
  .top-score {
    width: 100px;
    min-width: 180px;
  }
}