.discord-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
 
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
 
}

.gradient-earn-amount {

  background: linear-gradient(to right, #B5E5FF 0%, #C2CEFF 45%, #E1A9F6 65%, #F9ABE7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'SFProBold';
}


.title {
  text-overflow: ellipsis;
  font-family: SFProBold;
  font-size: 18px;
  color: white;
  margin-top: 5;

}

.buttons-box {

  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-image {

  height: 180px;
  width: 101%;
  position: absolute;
  top: 0%;
  object-fit: cover;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
}

.discord-page {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #181818;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 400px;
  padding-bottom: 30px;
}

.discord-container {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #242424;
  min-height: 420px;
  width: 500px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 30px;
  align-items: center;
  border-radius: 25px;
}


.discord-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  font-size: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-self: center;

  color: white;

}

.discord-headline {
  color: white;
  margin-left:4em;
  margin-right:4em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-family: 'SFProBold';
  text-shadow: 0px 0px 5px black;
  font-size: 16px;
}

.discord-steps {
  margin-top: 1em;
  margin-left: 5vh;
  margin-right: 5vh;
  display: flex;
  flex-direction: column;
  color: #919191;

  font-size: 16px;
  margin-bottom: 1em;
}

.discord-steps span {
  margin-bottom: 1em;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProRegular;
  padding-left: 10px;

}

.your-xp {
  color: #919191;
  font-size: 16px;
  font-family: SFProBold;
}

.close-window-button {
  margin-top: 10px;
  border: none;
  filter: none;
  width: 200px;
  height: 50px;
  padding: 8px;
  background: none;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProBold;
  color: white;

  border-radius: 30px;
  cursor: pointer;


}

.close-window-button:hover {
  background: rgba(0, 0, 0, .2);
}

.connect-discord-button {

  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));

  border: none;
  width: 200px;
  height: 50px;
  padding: 8px;
  background: #C6C7FF;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProBold;
  color: #171818;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border-radius: 30px;
  cursor: pointer;


}

.connect-discord-button:hover {
  background: #aaabfb;

}

.connect-discord-button-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border: none;
  width: 200px;
  height: 60px;
  padding: 8px;
  background: #C6C7FF;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProHeavy;
  color: 171818;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  cursor: pointer;
}

.loader img {
  width: 18px;
  height: 18px;
}

.error-message {
  color: #7D7D7D;
  margin-bottom: 0px;
  font-family: SFProRegular;
  text-shadow: 0px 0px 5px black;

}

.logo-box {

  padding-top: 15px;
  min-height: 80px;
  padding-bottom: 15px;
  margin-top: 90px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .discord-container {
    filter: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;


    width: 100%;
    min-height: 100%;

    padding-top: 0px;
    padding-bottom: 30px;
    align-items: center;
    border-radius: 0px;
    padding-bottom: 80px;
  }

  .logo-box {
    padding-top: 20px;
    min-height: 80px;
    padding-bottom: 15px;
    margin-top: 120px;
    margin-bottom: 5px;
    display: flex;
    position: relative;
    flex-direction: row;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .background-image {
    height: 200px;
    min-height: 200px;
    width: 101%;
    position: absolute;
    top: 0%;
    object-fit: cover;
   
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
  }


  .discord-page-container {
    background-color: none;
    height: 600px;
    min-width: 300px;
    justify-content: flex-start;



  }

  .discord-steps {
    margin-left: 6vh;
    margin-right: 6vh;

  }

}