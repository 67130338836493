.redemption-hr {
  background-color: #585858;
  width: 100%;
  margin: 20px 0px;
  border: none;
  border-radius: 5px;
  height: 5px
}

.redemption-hr.small {
  margin: 10px 0px !important;
}


.community-redemption-container {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background-color: #1E2028;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  padding-bottom: 30px;
  align-items: center;
  background-color: #1a1a1a
}

.community-redemption-container-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 400px;

}

.community-redemption-name {
  width: 100%;
}

.community-redemption-name>h1 {
  font-size: 18px;
  line-height: 18px;
  margin: 10px 0;
  color: #545454;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.community-redemption-name>h1:hover {
  font-size: 19px;
}

.redemption-container-top {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

}

.redemption-container-inner {
  display: flex;
  flex: 1;
  justify-content: center;

  /* align-items: center; */
}

.redemption-container-img {
  z-index: 4;
  display: flex;
  flex: 1;
  max-width: 400px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /* background: #101010; */
  cursor: pointer;
  transition: all 1s ease-in-out;
  /* box-shadow: 0px 0px 14px black; */
}

.redemption-container-img:hover {
  transform: scale(1.05);
  /* box-shadow: 0px 0px 16px black; */

}

.redemption-container-description {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  padding-top: 20px;
  /* height: 400px; */
}

.nft-name {
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  margin-bottom: 15px;
}

.nft-description-contain {
  width: 100%;
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
}

.nft-description {
  font-size: 16px;
  line-height: 20px;
  max-width: 90%;
  margin: 0;
}

.redemption-container-description>h2 {
  font-size: 16px;
  line-height: 16px;
  margin: 0
}


.redemption-container-editions {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222222;
  border-radius: 10px;
  padding: 2px;
  width: 100%;
  margin-bottom: 10px;
}

.redemption-container-editions>h1 {
  font-size: 20px;
}

.redemption-container-score-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.redemption-container-score-info-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.redemption-container-score {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.redeem-handler {

  position: relative;
  display: flex;
  height: 70px;
  width: 100%;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.redeem-handler-progress-contain {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0;
  background-color: #363636;
  width: 150px;
  height: 30px;
  border-radius: 15px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.redeem-handler-progress-contain:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 14px black;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

}

.redeem-handler-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #BCBFF5;
  z-index: 1;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.redeem-handler-progress-contain>h1 {
  font-size: 16px;
  z-index: 2;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.redemption-container-score-inner>h1 {
  font-size: 18px;
  line-height: 18px;
  margin: 0;
}

.nft-h3 {
  font-size: 12px;
  line-height: 12px;
  color: #919191;
  margin: 0;
  margin-bottom: 5px;

  font-family: SFProRegular;
}

.other-redemption-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.other-redemption-container-title {
  display: flex;
  align-self: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.other-redemption-container-title>h1 {
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  margin-bottom: 20px;
}

.other-redemption-container-map {
  display: grid;
  grid-template-columns: 180px 180px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.redemption-marquee {
  border-radius: 4px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background: linear-gradient(90.16deg, #F9ABE7 0.08%, #C6C7FF 54.16%, #B5E5FF 99.93%);
  height: 50px;
  box-shadow: 0px 0px 10px black;
}

.redemption-marque-txt {
  font-size: 18px;
  color: black;
  margin-left: 40px;

  text-shadow: 0px 0px 24px #0a0a0a;

}

.redemption-marque-header {
  margin-top: 2px;
  width: 100%;
  font-size: 16px;
  color: white;

  text-shadow: 0px 0px 24px #0a0a0a;
  text-align: left;
}

.redemption-marque-txt-scores {

  -webkit-text-fill-color: #0a0a0a;
  font-size: 18px;
  font-family: 'SFProBold';
}

.network-contain {
  display: flex;
  flex-direction: row;
  background: #333333;
  width: 90px;
  height: 28px;
  border-radius: 5px;
  justify-content: space-evenly;
  align-items: center;
}

.network-contain-name {
  font-size: 14px;
}

.redeem-spinner {
  margin-right: 10px;
  animation: rotation 2s infinite linear;
}

.redemption-container-score-avatar {
  position: relative;
  height: 42px;
  width: 110px;
}

.redemption-container-score-collectors {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@media screen and (min-width: 720px) {
  .redemption-container-top {
    position: relative;
    flex-direction: row;
  }

  .redemption-container-description {
    padding: 20px;
    padding-bottom: 0px;
  }

  .community-redemption-container-inner {
    max-width: 600px;
  }

  .redemption-marque-txt {
    margin-left: 60px;
  }

  .other-redemption-container-map {
    grid-template-columns: 180px 180px 180px;
  }
}

@media screen and (min-width: 800px) {
  .community-redemption-container-inner {
    max-width: 800px;
  }

  .other-redemption-container-map {
    grid-template-columns: 180px 180px 180px 180px;
  }
}