.modal-backdrop {
  background-color: rgba(0, 0, 0, .7);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.modal-container {
  flex-direction: column;
  z-index: 2;
  overflow: hidden;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #171818;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.7));
  border-radius: 20px;
  width: 90%;
  height: fit-content;
  padding-bottom: 3em;
  -webkit-font-smoothing: antialiased;
  display: flex;
}

.close-modal {
  position: absolute;
  display: flex;

  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}

.close-icon {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  padding: 6px;
  background-color: rgba(0, 0, 0, .2);
  color: white;
  font-size: large;
  margin-right: 1em;
  cursor: pointer;
}

.close-icon:hover {
  background-color: rgba(0, 0, 0, .8);

}

.modal-content {
  margin-top: 5%;
  ;
  font-family: SFProRegular;
  margin-left: 2em;
}

.modal-header {
  color: #C6C7FF;
  font-weight: 600;
  font-size: large;
  margin-bottom: 1em;

  -webkit-font-smoothing: subpixel-antialiased;
}

.form-title {
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0.5em;
  padding-left: 0.3em;
}

.optional {
  color: #A7A7A7;
  font-size: 13px;
  font-weight: 500;
  padding-left: 0.2em;
}

form {
  display: flex;
  flex-direction: column;
}

input[type="text"] {

  all: unset;
  background: #2E2E2E;
  color: white;
  font-size: 13px;
  font-weight: 900;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 50px;

  width: 82%;
  height: 3em;
  padding: 0 0.5em 0 1em;
  margin-bottom: 1em;
}

.choose-img {
  color: white;
  font-weight: 600;
  font-family: SFProRegular;
  margin-bottom: 2em;
}

.choose-img::file-selector-button {
  border: none;
  padding: 6px 10px 6px 10px;
  margin-right: 1em;
  background: #C6C7FF;
  font-weight: 500;
  font-family: SFProRegular;
  color: 171818;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  cursor: pointer;
  color: black;
}

.choose-img::file-selector-button:hover {
  background: #aaabfb;
}

.modal-button {
  border: none;
  width: 85%;
  padding: 8px;
  background: #C6C7FF;
  font-weight: 900;
  font-size: 16px;
  font-family: SFProRegular;
  color: 171818;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  cursor: pointer;
  color: black;
}

.modal-button:hover {
  background: #aaabfb;
}

.modal-button-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;

}

.modal-button img {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  margin-top: 2px;
}

.form-error {
  display: flex;
  justify-content: center;
  width: 85%;
  margin-bottom: 1em;
  color: rgb(222, 22, 22);
  font-weight: 600;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'SFProRegular';
}

.image-modal-container {
  flex-direction: column;
  z-index: 999;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: #171818;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.7));
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  padding-bottom: 3em;
}

.modal-open {
  display: block;
}

.modal-closed {
  display: none;
}


.image-modal-content {
  font-family: SFProRegular;
  margin-left: 3em;
  margin-right: 3em;
}

.image-modal-content img {
  max-width: 90vw;
  max-height: 80vh;
}


@media screen and (min-width: 600px) {
  .modal-container {
    width: 400px;
  }
}

@media screen and (max-width: 800px) {
  .image-modal-content img {
    width: 80vw;
  }
}