.home-container-outer {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #1E2028;
  background-image: url('../../images/huddlnBg.png');
  /* -o-background-size: 35% auto; */
  /* -webkit-background-size: 35% auto; */
  /* -moz-background-size: 35% auto; */
  /* background-size: 35% auto; */
  /* background-repeat: repeat */
}

.home-container {
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  width: 95%;
  max-width: 520px;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
}

.home-container-inner {
  display: grid;
  grid-template-columns: 395px;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.action-button {
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  transition: all .1s ease-in-out;
}

.action-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);

}

.home-container-header {
  margin-top: 10px;
  color: white;
}

.home-container-header>h1 {
  font-size: 22px;
  text-shadow: black 1px 0 10px;
  font-family: 'SFProHeavy';
}

.home-container-header-lower {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 0px;
}

.home-container-header-lower-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

.home-container-header-lower-left>img {
  margin-right: 5px;
}

.home-container-header-lower-left>p {
  font-weight: 500;
  font-size: 18px;
  color: #BCBFF5;
  text-shadow: black 1px 0 10px;
}

.hero-card {
  width: 100%;




  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  transition: all .2s ease-in-out;

}

.featured-card {
  width: 100%;
  width: 395px;
  aspect-ratio: 16 / 6;
  min-height: 265px;
  max-height: 455px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  -webkit-box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 54px 0px rgba(0, 0, 0, 0.75);
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.featured-card:hover {
  transform: scale(1.05);
}

.bg-logo {
  width: 60%;
}

.create-xp-btn {
  background: #323232;
}

.featured-card-bg {
  background-color: #1A1A1A;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;

}

.featured-card-top {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.featured-card-top-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.featured-card-avatar-bg {
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  -webkit-box-shadow: 10px 10px 54px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 54px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 54px -19px rgba(0, 0, 0, 0.75);
}

.featured-card-avatar {
  height: 62px;
  width: 62px;
  object-fit: fill;
  border-radius: 50%;
}

.featured-card-top-left>h2 {
  color: white;
  text-shadow: 1px 1px 2px black;
}

.featured-card-top-right {}

.featured-card-top-right>h1 {
  filter: drop-shadow(2px 2px black);
  background: -webkit-linear-gradient(45deg,
      #b5e5ff 2.35%,
      #c2ceff 31.77%,
      #e1a9f6 68.99%,
      #f9abe7 102.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.featured-card-top-right>h3 {
  color: #bbb;
  text-shadow: 1px 1px 2px black;
}

.home-container-header-lower-pill {
  width: 471px;
  height: 67px;
  background: #353535;
  border-radius: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  -webkit-box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
}

.home-card-wrapper {
  display: flex;
  flex: 1 100%;
  justify-content: center;
}

.home-card {
  width: 95%;
  width: 395px;
  height: 265px;
  background: #1A1A1A;
  border-radius: 20px;
  margin-top: 25px;
  overflow: hidden;
  position: relative;
  transition: all .2s ease-in-out;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 36px 2px rgba(0, 0, 0, 0.75);
}

.home-card.hovered {
  transition: all .2s ease-in-out;
}

.home-card.hovered:hover {
  transform: scale(1.1);
}

.home-card-bg {
  width: 100%;
  height: 95px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
  z-index: 10;
}

.home-card-content {
  z-index: 10;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  /* justify-content: space-between;
  align-items: center; */
  /* margin-top: 47.5px; */
  /* margin-top: 57.5px; */
  margin-top: 72.5px;
  position: relative;
}

.home-card-image {
  height: 72px;
  min-width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  margin-left: 16px;
  -webkit-box-shadow: 10px 10px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 21px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 21px -6px rgba(0, 0, 0, 0.75);
  position: relative;
}

.home-card-image-inner {
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.home-card-content-right {
  margin-top: 25px;
  padding: 4px 16px;
}

.home-card-content-right>h2 {
  color: white;
  font-family: 'SFProHeavy';
  font-size: 20px;
}

.home-card-content-right>p {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #919191;
}

.home-card-content-right-xp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.home-card-content-right-xp>h5 {
  color: white;
}

.home-gradient {
  background: -webkit-linear-gradient(45deg,
      #b5e5ff 2.35%,
      #c2ceff 31.77%,
      #e1a9f6 68.99%,
      #f9abe7 102.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 5px;
}

.home-gradient-members {
  margin-right: 15px;
}

.home-horizontal-line {
  width: 100%;
  height: 10px;
  background-color: #313131;
  border-radius: 5px;
  margin-top: 20px;
}

.xp-logo-container {
  display: flex;
}

.xp-logo-container-1 {
  display: none;
}

@media screen and (max-width:900px) {
  .xp-logo-container {
    display: none;
  }

  .xp-logo-container-1 {
    display: flex;

  }

  .hero-card-main-content {
    flex-direction: column;
  }

  .bg-logo {
    width: 50%;
  }

  .action-button:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);

  }

}

@media screen and (min-width: 900px) {
  .home-container {
    max-width: 820px;
    width: 100%;

  }

  .home-container-inner {
    grid-template-columns: 395px 395px;
    justify-content: space-between;
  }

  .home-container-header-lower {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .home-container-header-lower-left {
    margin-bottom: 0px;
  }

  .home-container-header-lower-left>img {
    margin-right: 10px;
  }

  .featured-card {
    border-radius: 30px;
    width: 100%;
  }

  .home-card-wrapper {
    flex: 1 50%;
    justify-content: center;
  }

  .home-card {
    margin-top: 30px;
  }

  .home-container-header {
    margin-top: 50px;
  }

  .featured-card-top {
    padding: 22px;
  }
}

@media screen and (min-width: 1620px) {
  .home-container-inner {
    grid-template-columns: 395px 395px 395px;
  }

  .featured-card {
    border-radius: 40px;
    width: 100%;
  }

  .home-card-wrapper {
    flex: 1 33%;
    /* justify-content: space-evenly; */
  }

  .home-container {
    max-width: 1260px;
  }

  .home-container-header-lower-left {
    margin-bottom: 15px;
  }

  .featured-card-top {
    padding: 30px;
  }

  .bg-logo {
    width: 30%;
  }

  .xp-logo-container {
    width: 60%;
  }
}