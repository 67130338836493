body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* margin-top: min(50px, 5%); */
}

.error-message {
  margin-top: 20px;
  color: #7d7d7d;
  margin-bottom: 0px;
  font-family: SFProBold;
  text-shadow: 0px 0px 5px black;

}

.xpClaim-container {
  display: flex;
  /* flex-direction: column; */
  background-color: #171818;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-smooth: always;
}

.xpClaim-square {
  -webkit-box-sizing: border-box;
  /* Safari Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera IE 8+ */
  background-color: #1e1e1e;
  border-radius: 10px;
  margin-top: 100px;
  width: 100%;
  max-width: 850px;
  /* height: 100vh; */
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.xpClaim-title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.xpClaim-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}


.xp-background-image {
  height: 180px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
}

.xpClaim-content {
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.xpClaim-userInfo {
  margin-top: 90px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.xpClaim-qr {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}

.xpClaim-userImage-boarder {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: white;
  /* margin-bottom: 0px; */
  -webkit-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  background-size: cover;
  /* overflow: hidden; */
  position: relative;
}

.xpClaim-userImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.xpClaim-username {
  font-size: 20px;
  /* line-height: 39px; */
  margin-top: 10px;
  font-weight: 700;
  background: -webkit-linear-gradient(45deg,
      #b5e5ff 2.35%,
      #c2ceff 31.77%,
      #e1a9f6 68.99%,
      #f9abe7 102.02%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.xpClaim-qrGradient {
  width: 180px;
  height: 180px;
  background: linear-gradient(-90deg,
      #56ccf2 -7.25%,
      #c6c7ff 24.16%,
      #e1a9f6 55.53%,
      #f9abe7 108.02%);
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  transition: all .2s ease-in-out;
}

.xpClaim-qrGradient-modal {
  width: 340px;
  height: 340px;
  background: linear-gradient(-90deg,
      #56ccf2 -7.25%,
      #c6c7ff 24.16%,
      #e1a9f6 55.53%,
      #f9abe7 108.02%);
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 41px -8px rgba(0, 0, 0, 0.75);
  transition: all .2s ease-in-out;
}


.xpClaim-qrGradient:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.xpClaim-qrBarcode {
  width: 170px;
  height: 170px;
  background-color: #1e1e1e;
  border-radius: 20px;
}

.xpClaim-qrBarcode-modal {
  width: 330px;
  height: 330px;
  background-color: #1e1e1e;
  border-radius: 20px;
}

.xpClaim-info {
  margin-bottom: 20px;
}

.xpClaim-name {
  font-weight: 700;
  font-size: 20px;
  color: white;
  margin: 10px 0px;

}

.xpClaim-description {
  font-weight: 500;
  font-size: 16px;
  color: #a7a7a7;
}

.xpClaim-button {
  width: 168px;
  height: 45px;
  background: #c6c7ff;
  border-radius: 45px;
}

.xpClaim-button-title {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.xpClaim-button-title.close {
  color: white;
}

.xpClaim-loader {
  width: 18px;
  height: 18px;
  border: 2px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 768px) {
  /* .xpClaim-title {
    margin-bottom: 30px;
  } */

  .xpClaim-top {
    flex-direction: row;
    /* margin: 0; */
  }

  .xpClaim-square {
    padding-left: 60px;
    padding-right: 60px;
  }

  .xpClaim-userImage {
    margin-bottom: 20px;
  }
}



@media screen and (min-width: 600px) {
  .main-container {
    justify-content: center;
  }

  .xpClaim-container {
    align-items: center;
  }

  .xpClaim-content {
    flex-direction: row;
  }

  .xpClaim-qr {
    margin-top: 40px;
  }

  .xpClaim-qrGradient-modal {
    width: 480px;
    height: 480px;
  }

  .xpClaim-qrBarcode-modal {
    width: 460px;
    height: 460px;

  }
}